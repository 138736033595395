$('[data-toggle=tooltip]').tooltip();

// Mobile nav
$('#navtoggle').click(function() {
    $('#mainnav').slideToggle();
});

// Pageselector
$('.pageselector select').change(function(){
    var url = $(this).val();
    if (url) {
        window.location.href = url;
    }
});
